import GroupComponent from "./GroupComponent";
import PropTypes from "prop-types";
import styles from "./BenefitItem.module.css";
import { benefitItemCopy } from "./copy";

const BenefitItem = ({ className = "" }) => {
  return (
    <section className={[styles.benefitItem, className].join(" ")}>
      <div className={styles.questions}>
        <div className={styles.questionTitle}>
          <h1 className={styles.gotQuestionsWere}>
            {benefitItemCopy.heading}
          </h1>
        </div>
        <div className={styles.faq}>
          {benefitItemCopy.faqs.map((faq, index) => (
            <GroupComponent
              key={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

BenefitItem.propTypes = {
  className: PropTypes.string,
};

export default BenefitItem;
