import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import styles from "./ActionBox.module.css";

const ActionBox = ({
  className = "",
  direction = "left",
  header = "",
  body = "",
  image = "",
}) => {
  const [currentDirection, setCurrentDirection] = useState(direction);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 875) {
        setCurrentDirection("right");
      } else {
        setCurrentDirection(direction);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial direction based on current window size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [direction]);

  return (
    <section
      className={
        [styles.actionBox, className].join(" ") +
        " " +
        (currentDirection === "left" ? styles.left : styles.right)
      }
    >
      {image && (
        <div
          className={
            styles.imageWrapper +
            " " +
            (currentDirection === "left"
              ? styles.imageWrapperLeft
              : styles.imageWrapperRight)
          }
          style={{
            order: currentDirection === "left" ? 2 : 0,
          }}
        >
          <img src={image} alt="" className={styles.image} />
        </div>
      )}

      <div className={styles.actionBoxWrapper + " " + currentDirection}>
        <div className={styles.textBoxContainer}>
          <div className={styles.textBox} />
          <h2 className={styles.header}>{header}</h2>
          <b className={styles.body}>{body}</b>
        </div>
      </div>
    </section>
  );
};

ActionBox.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
};

export default ActionBox;
