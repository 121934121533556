import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AppNotes.module.css';


export const appnoteItems = [
    {
        title: "Circular Plasmid Design with Gibson Assembly",
        author: "LabKick Team",
        date: "Aug 15, 2024",
        tags: ["CIRCULAR", "GIBSON"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0001.md",
        redirectId: "article_0001",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0001/labkick_project_files.zip"
    },
    {
        title: "Golden Gate Assembly for Modular Protein Engineering",
        author: "LabKick Team",
        date: "Sep 2, 2024",
        tags: ["CIRCULAR", "GOLDEN_GATE"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0002.md",
        redirectId: "article_0002",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0002/labkick_project_files.zip"
    },
    {
        title: "High-throughput Gibson Assembly for Gene Library Construction",
        author: "LabKick Team",
        date: "Sep 10, 2024",
        tags: ["LINEAR", "GIBSON"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0003.md",
        redirectId: "article_0003",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0003/labkick_project_files.zip"
    },
    {
        title: "Multipart Plasmid Assembly with Restriction Enzyme Validation",
        author: "LabKick Team",
        date: "Sep 18, 2024",
        tags: ["CIRCULAR", "TRADITIONAL"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0004.md",
        redirectId: "article_0004",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0004/labkick_project_files.zip"
    },
    {
        title: "Designing CRISPR Knock-in Constructs with Homology Arms",
        author: "LabKick Team",
        date: "Sep 25, 2024",
        tags: ["LINEAR", "CRISPR"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0005.md",
        redirectId: "article_0005",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0005/labkick_project_files.zip"
    },
    {
        title: "Optimized Primer Design for Seamless Cloning",
        author: "LabKick Team",
        date: "Oct 3, 2024",
        tags: ["PRIMER_DESIGN", "SEAMLESS_CLONING"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0006.md",
        redirectId: "article_0006",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0006/labkick_project_files.zip"
    },
    {
        title: "Automated Restriction Site Analysis for Vector Backbone Selection",
        author: "LabKick Team",
        date: "Oct 12, 2024",
        tags: ["RESTRICTION_ANALYSIS", "VECTOR_SELECTION"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0007.md",
        redirectId: "article_0007",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0007/labkick_project_files.zip"
    },
    {
        title: "Combinatorial Tag Library Design for Protein Purification",
        author: "LabKick Team",
        date: "Oct 20, 2024",
        tags: ["COMBINATORIAL", "GOLDEN_GATE"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0008.md",
        redirectId: "article_0008",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0008/labkick_project_files.zip"
    },
    {
        title: "Gantt Chart Integration for Multi-step Cloning Projects",
        author: "LabKick Team",
        date: "Oct 28, 2024",
        tags: ["PROJECT_MANAGEMENT", "GANTT_CHART"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0009.md",
        redirectId: "article_0009",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0009/labkick_project_files.zip"
    },
    {
        title: "Automated Bill of Materials Generation for Large-scale Assembly",
        author: "LabKick Team",
        date: "Nov 5, 2024",
        tags: ["BILL_OF_MATERIALS", "HIGH_THROUGHPUT"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg",
        contentFile: "article_0010.md",
        redirectId: "article_0010",
        downloadUrl: "https://labkickarticles.s3.us-east-2.amazonaws.com/article_id_0010/labkick_project_files.zip"
    }
];

const futureAppnoteItems = [
    {
        title: "AI-Powered DNA Reuse from Existing Plasmid Library",
        author: "LabKick Team",
        date: "Coming Soon",
        tags: ["DNA_REUSE", "AI"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg"
    },
    {
        title: "Oligo Tiling Optimization for Long DNA Synthesis",
        author: "LabKick Team",
        date: "Coming Soon",
        tags: ["OLIGO_TILING", "DNA_SYNTHESIS"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg"
    },
    {
        title: "Echo Liquid Handler Integration for High-throughput Assembly",
        author: "LabKick Team",
        date: "Coming Soon",
        tags: ["ECHO_WORKLISTS", "AUTOMATION"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg"
    },
    {
        title: "Automated Assembly Planning from Target Sequences",
        author: "LabKick Team",
        date: "Coming Soon",
        tags: ["AUTOMATED_PLANNING", "TARGET_SEQUENCE"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg"
    },
    {
        title: "Machine Learning-based Optimal Assembly Strategy Prediction",
        author: "LabKick Team",
        date: "Coming Soon",
        tags: ["MACHINE_LEARNING", "STRATEGY_OPTIMIZATION"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg"
    },
    {
        title: "Integrated Wetlab Robotics Workflow Generation",
        author: "LabKick Team",
        date: "Coming Soon",
        tags: ["ROBOTICS", "WORKFLOW_GENERATION"],
        image: "/LabKick_Favicons_All_LabKick_Fav_192.svg"
    }
];

const gradients = [
    { start: 'rgba(255,140,0,0.1)', end: 'rgba(255,140,0,0.3)' }, // Orange
    { start: 'rgba(235,38,241,0.1)', end: 'rgba(235,38,241,0.3)' }, // Pink
    { start: 'rgba(255,140,0,0.05)', end: 'rgba(235,38,241,0.15)' }, // Orange to Pink
    { start: 'rgba(235,38,241,0.05)', end: 'rgba(255,140,0,0.15)' }, // Pink to Orange
    { start: 'rgba(255,140,0,0.1)', end: 'rgba(255,140,0,0.2)' }, // Light Orange
    { start: 'rgba(235,38,241,0.1)', end: 'rgba(235,38,241,0.2)' }, // Light Pink
];

function getRandomGradient() {
    return gradients[Math.floor(Math.random() * gradients.length)];
}

function AppNoteCard({ item, isFuture }) {
    const navigate = useNavigate();
    const cardRef = useRef(null);
    const titleRef = useRef(null);
    const cardClass = isFuture ? styles.futureCard : styles.card;

    const handleClick = () => {
        if (!isFuture) {
            const slug = item.title.toLowerCase().replace(/\s+/g, '-');
            navigate(`/appnotes/${slug}`);
        }
    };

    useEffect(() => {
        if (!isFuture && cardRef.current) {
            const gradient = getRandomGradient();
            cardRef.current.style.setProperty('--gradient-start', gradient.start);
            cardRef.current.style.setProperty('--gradient-end', gradient.end);
        }

        // Ensure the title takes up at least two lines
        if (titleRef.current) {
            const lineHeight = parseInt(window.getComputedStyle(titleRef.current).lineHeight);
            const minHeight = lineHeight * 2;
            if (titleRef.current.offsetHeight < minHeight) {
                titleRef.current.style.height = `${minHeight}px`;
            }
        }
    }, [isFuture]);

    return (
        <div className={cardClass} ref={cardRef} onClick={handleClick}>
            <h2 className={styles.cardTitle} ref={titleRef}>{item.title}</h2>
            <div className={styles.authorInfo}>
                <img src={item.image} alt={item.title} className={styles.avatar} />
                <span className={styles.authorName}>{item.author}</span>
            </div>
            <div className={styles.cardFooter}>
                <p className={styles.date}>{item.date}</p>
                <div className={styles.tags}>
                    {item.tags.map((tag, tagIndex) => (
                        <span key={tagIndex} className={styles.tag} data-type={tag}>{tag}</span>
                    ))}
                </div>
            </div>
        </div>
    );
}

function AppNotes() {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>LabKick App Notes</h1>

            <h2 className={styles.sectionTitle}>Current Workflows</h2>
            <div className={styles.grid}>
                {appnoteItems.map((item, index) => (
                    <AppNoteCard key={index} item={item} isFuture={false} />
                ))}
            </div>

            <h2 className={styles.sectionTitle}>Future Workflows</h2>
            <div className={styles.grid}>
                {futureAppnoteItems.map((item, index) => (
                    <AppNoteCard key={index} item={item} isFuture={true} />
                ))}
            </div>
        </div>
    );
}

export default AppNotes;
