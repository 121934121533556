import PropTypes from "prop-types";
import styles from "./FrameComponent2.module.css";
import CustomButton from "./Button";
import { frameComponent2Copy } from "./copy";

const handleLogIn = () => {
  window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
};

const FrameComponent2 = ({ className = "" }) => {
  return (
    <section className={[styles.imageDescriptionParent, className].join(" ")}>
      <div className={styles.imageDescription}>
        <div className={styles.footerCtaShapeParent}>
          <h1 className={styles.footerCta}>{frameComponent2Copy.heading}</h1>
          <CustomButton
            propBackgroundColor="transparent"
            propHeight="3.125rem"
            propFlex="0"
            propBorderRadius="31px"
            propBackground="linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea)"
            propPadding="8px 13px 8px 18px"
            filledButtonCTA="sign up"
            propTextAlign="center"
            propTextDecoration="none"
            onClick={handleLogIn}
            className={styles.signUpButton}
          />
        </div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footerTop}>
          <div className={styles.footerBranding}>
            <img
              className={styles.footerLogoIcon}
              loading="lazy"
              alt=""
              src="/logo.svg"
            />
            <div className={styles.labkickAllRights}>
              {frameComponent2Copy.copyright}
            </div>
          </div>
          <div className={styles.footerLinks}>
            <div className={styles.socialIcons}>
              <a
                href={frameComponent2Copy.socialLinks.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.image6Icon}
                  loading="lazy"
                  alt=""
                  src="/image-7@2x.png"
                />
              </a>
              <a
                href={frameComponent2Copy.socialLinks.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.image6Icon}
                  loading="lazy"
                  alt=""
                  src="/image-8@2x.png"
                />
              </a>
            </div>
            <div className={styles.footerLinksMobile}>
              <a 
                href={frameComponent2Copy.footerLinks.contact.href} 
                className={styles.contactInfo}
              >
                {frameComponent2Copy.footerLinks.contact.text}
              </a>
              <a
                href={frameComponent2Copy.footerLinks.privacy.href}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.legalStuff}
              >
                {frameComponent2Copy.footerLinks.privacy.text}
              </a>
              <a
                href={frameComponent2Copy.footerLinks.terms.href}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.legalStuff + " " + styles.termsOfService}
              >
                {frameComponent2Copy.footerLinks.terms.text}
              </a>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
