import React, { forwardRef } from "react";
import styles from "../pages/Desktop.module.css";
import BenefitItem from "./BenefitItem";
import FrameComponent2 from "./FrameComponent2";

const QuestionsSection = forwardRef(({ className = "" }, ref) => {
  return (
    <div
      ref={ref}
      className={`${styles.questions} ${styles.responsiveQuestions}`}
    >
      <img
        className={styles.logoBannerIcon}
        loading="lazy"
        alt=""
        src="/logobanner.svg"
      />
      <BenefitItem />
      <FrameComponent2 />
      <section className={styles.vectorParent}>
        <img className={styles.frameChild} alt="" src="/rectangle-107.svg" />
        <img
          className={styles.dynamicLinesIcon}
          alt=""
          src="/dynamic-lines-faq.svg"
        />
      </section>
    </div>
  );
});

export default QuestionsSection;
