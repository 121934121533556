import React, { useRef, useEffect } from 'react';
import TopSection from "../components/TopSection";
import BenefitItem from "../components/BenefitItem";
import BenefitImage from "../components/BenefitImage";
import FrameComponent2 from "../components/FrameComponent2";
import styles from "./Desktop.module.css";

import SecondSection from "../components/SecondSection";
import PaymentSection from "../components/PaymentSection";
import QuestionsSection from "../components/QuestionsSection";
import NavDots from "../components/NavDots";

const Desktop = () => {
  const topSectionRef = useRef(null);
  const secondSectionRef = useRef(null);
  const paymentSectionRef = useRef(null);
  const questionsSectionRef = useRef(null);

  const sectionRefs = [topSectionRef, secondSectionRef, paymentSectionRef, questionsSectionRef];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={styles.desktop29}>
      <TopSection ref={topSectionRef} />
      <NavDots sectionRefs={sectionRefs} />
      <SecondSection ref={secondSectionRef} />
      <PaymentSection ref={paymentSectionRef} />
      <QuestionsSection ref={questionsSectionRef} />
      {/* <img
        className={styles.logoBannerIcon}
        loading="lazy"
        alt=""
        src="/logobanner.svg"
      />
      <BenefitItem />
      <BenefitImage />
      <FrameComponent2 />
      <section className={styles.vectorParent}>
        <img className={styles.frameChild} alt="" src="/rectangle-107.svg" />
        <img
          className={styles.dynamicLinesIcon}
          alt=""
          src="/dynamiclines@2x.png"
        />
      </section> */}

      <div className={styles.signUpWrapper}>
        <div className={styles.signUp}>
          <div className={styles.signUpNow}>sign up now</div>
        </div>
      </div>
      <b className={styles.example}>EXAMPLE</b>
      <div className={styles.desktop29Child} />
    </div>
  );
};

export default Desktop;
