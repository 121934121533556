import PropTypes from "prop-types";
import styles from "./PaymentTierTwo.module.css";
import { paymentTiersCopy } from "./copy";

const PaymentTierTwo = ({ className = "" }) => {
  const { professional } = paymentTiersCopy;

  const handlePurchase = () => {
    window.open(
      professional.button.url,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className={[styles.paymentTierTwo, className].join(" ")}>
      <div className={styles.paymentTierTwoChild} />
      <div className={styles.professionalContent}>
        <h2 className={styles.professional}>{professional.title}</h2>
        <div className={styles.professionalPricing}>
          <b className={styles.priceSymbol}>{professional.pricing.price}</b>
          <b className={styles.perLabchip}>{professional.pricing.subtitle}</b>
        </div>
        <button className={styles.signUp} onClick={handlePurchase}>
          <div className={styles.purchaseNow}>{professional.button.text}</div>
        </button>
      </div>
      <div className={styles.professionalDetails}>
        <b className={styles.chatsPerMonthContainer}>
          {professional.features.slice(0, 2).map((feature, index) => (
            <p key={index} className={styles.chatsPerMonth}>{feature}</p>
          ))}
        </b>
        <div className={styles.securityHighlight}>
          <div className={styles.divider} />
          <div className={styles.chatsPerMonthContainer}>
            {professional.features[2]}
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentTierTwo.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierTwo;
