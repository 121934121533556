import PropTypes from "prop-types";
import styles from "./PaymentTierThree.module.css";
import { paymentTiersCopy } from "./copy";

const PaymentTierThree = ({ className = "" }) => {
  const { enterprise } = paymentTiersCopy;

  const handleContactUs = () => {
    window.location.href = enterprise.button.url;
  };

  return (
    <div className={[styles.paymentTierThree, className].join(" ")}>
      <div className={styles.paymentTierThreeChild} />
      <div className={styles.enterpriseContent}>
        <h2 className={styles.enterprise}>{enterprise.title}</h2>
      </div>
      <div className={styles.enterpriseDescription}>
        <b className={styles.customSolutionsAvailableForContainer}>
          {enterprise.description.map((line, index) => (
            <p key={index} className={styles.customSolutionsAvailable}>
              {line}
            </p>
          ))}
        </b>
      </div>
      <button className={styles.signUp} onClick={handleContactUs}>
        <div className={styles.contactUs}>{enterprise.button.text}</div>
      </button>
      <div className={styles.security}>
        <div className={styles.planInfo}>
          <b className={styles.unlimitedChatsPerContainer}>
            {enterprise.features.slice(0, 2).map((feature, index) => (
              <p key={index} className={styles.customSolutionsAvailable}>
                {feature}
              </p>
            ))}
          </b>
          <div className={styles.divider} />
        </div>
        <div className={styles.enterpriseGradeSecurityElnContainer}>
          {enterprise.features.slice(2).map((feature, index) => (
            <p key={index} className={styles.enterpriseGradeSecurity}>
              {feature}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

PaymentTierThree.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierThree;
