import React, { forwardRef, useState } from "react";
import CustomButton from "./Button";
import FeatureBox from "./FeatureBox";
import PropTypes from "prop-types";
import styles from "./TopSection.module.css";
import { topSectionCopy } from "./copy";

const TopSection = forwardRef(({ className = "" }, ref) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleSignUp = () => {
    console.log("Sign up button clicked");
    window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
  };

  const handleLogIn = () => {
    window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
  };

  const handleVideoClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <section ref={ref} className={[styles.frameParent, className].join(" ")}>
      <div className={styles.backgroundImageParent}>
        <div className={styles.backgroundImage}></div>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <img
              className={styles.logoIcon}
              loading="lazy"
              alt=""
              src="/logo.svg"
            />

            <div className={styles.signUpLogin}>
              <CustomButton
                propBackgroundColor="transparent"
                propHeight="3.125rem"
                // propFlex="1"
                propBorderRadius="31px"
                propBackground="linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea)"
                propPadding="8px 13px 8px 18px"
                filledButtonCTA="sign up"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleSignUp}
              />
              <CustomButton
                propBackgroundColor="#FF8C00"
                propHeight="3.125rem"
                // propFlex="0.7564"
                propBorderRadius="31px"
                propBackground="#FF8C00"
                propPadding="8px 25px"
                filledButtonCTA="log in"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleLogIn}
              />
            </div>
          </div>
          <div className={styles.sidekickContentWrapper}>
            <div className={styles.backgroundContainer}>
              <img alt="" src="/logo_with_lines.svg" />
              {/* <img alt="" src="/LabKick_Logo_With_Lines.svg" /> */}
            </div>
            <div className={styles.introBigContainer}>
              <div className={styles.introBig}>
                <h1 className={styles.meetYourDna}>
                  {topSectionCopy.mainHeading}
                </h1>
              </div>
              <div className={styles.introTextBodyContainer}>
                <b className={styles.introTextBody}>
                  <p className={styles.introText}>
                    {topSectionCopy.introText}
                  </p>
                </b>
              </div>
            </div>
          </div>
          <div className={styles.videoSection}>
            <div className={styles.videoPlaceholder}>
              {isPlaying ? (
                <video 
                  className={styles.heroVideo}
                  autoPlay
                  controls
                  src="/example.mp4"
                />
              ) : (
                <div className={styles.heroImagevideo} onClick={handleVideoClick}>
                  <img
                    className={styles.iconPlayCircle}
                    loading="lazy"
                    alt=""
                    src="/-icon-play-circle.svg"
                  />
                </div>
              )}
            </div>
            <div className={styles.customButtonWrapper}>
              <CustomButton
                filledButtonCTA="get started"
                onClick={handleLogIn}
              />
            </div>
          </div>

          <div className={styles.examplesWithIcons}>
            {topSectionCopy.features.map((feature, index) => (
              <FeatureBox
                key={index}
                header={feature.header}
                text={feature.text}
                icon={`/${["group-48", "lightbulb", "group-52", "group-46", "group-41", "broom"][index]}.svg`}
              />
            ))}
          </div>
          <div className={styles.dividerText}>
            <h1 className={styles.canYouKickContainer}>
              <span>{topSectionCopy.dividerText.part1}</span>
              <span className={styles.span}>{` `}</span>
              <span className={styles.yesYouCan}>{topSectionCopy.dividerText.part2}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
    // comment
  );
});

TopSection.propTypes = {
  className: PropTypes.string,
};

export default TopSection;
