import React, { forwardRef } from "react";
import styles from "../pages/Desktop.module.css";
import ActionBox from "./ActionBox";
import { secondSectionCopy } from "./copy";

const SecondSection = forwardRef(({ className = "" }, ref) => {
  return (
    <div ref={ref} className={styles.secondSection}>
      <div className={styles.coloredLinesFixed}>
        <img src="/colored-line-shapes.svg" alt="LabKick" />
      </div>
      <div className={styles.splashStatement}>
        <b className={styles.splashStatementText}>
          {secondSectionCopy.splashStatement}
        </b>
      </div>
      <section className={styles.backgroundParent}>
        <div className={styles.background} />
        <div className={styles.gradient} />
      </section>

      {secondSectionCopy.actionBoxes.map((box, index) => (
        <ActionBox
          key={index}
          direction={box.direction}
          header={box.header}
          body={box.body}
          image={box.image}
        />
      ))}
    </div>
  );
});

export default SecondSection;
