import React, { useState, useEffect, useRef } from 'react';
import styles from './NavDots.module.css';

const NavDots = ({ sectionRefs }) => {
  const [selectedDot, setSelectedDot] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const isScrollingRef = useRef(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsVisible(window.innerWidth > 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    const handleScroll = () => {
      if (!isScrollingRef.current) {
        const scrollPosition = window.scrollY + window.innerHeight / 2;

        for (let i = sectionRefs.length - 1; i >= 0; i--) {
          if (sectionRefs[i].current && scrollPosition >= sectionRefs[i].current.offsetTop) {
            setSelectedDot(i);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [sectionRefs]);

  const scrollToSection = (index) => {
    isScrollingRef.current = true;
    setSelectedDot(index);
    if (sectionRefs[index] && sectionRefs[index].current) {
      const targetPosition = sectionRefs[index].current.offsetTop;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });

      // Clear any existing timeout
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      // Set a timeout to allow scrolling again after animation
      timeoutRef.current = setTimeout(() => {
        isScrollingRef.current = false;
      }, 1000); // Adjust this value to match your scroll animation duration
    }
  };

  // Easing function for smooth animation
  const easeInOutCubic = (t) => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

  if (!isVisible) return null;

  return (
    <div className={styles.navDotsContainer}>
      {[0, 1, 2, 3].map((index) => (
        <div
          key={index}
          className={`${styles.dot} ${index === selectedDot ? styles.selected : ''}`}
          onClick={() => scrollToSection(index)}
        />
      ))}
    </div>
  );
};

export default NavDots;
