import styles from "./FeatureBox.module.css";

const FeatureBox = ({ className = "", header, text, icon }) => {
  return (
    <div className={[styles.talkContainer, className].join(" ")}>
      <div className={styles.headerContainerWrapper}>
        <h2 className={styles.headerContainer}>
          <p
            className={styles.header}
            dangerouslySetInnerHTML={{ __html: header }}
          ></p>
        </h2>
      </div>
      <div className={styles.featureDetails}>
        <b className={styles.feature}>{text}</b>
      </div>
      <div className={styles.talkContainerInner}>
        <img className={styles.frameChild} loading="lazy" alt="" src={icon} />
      </div>
      <div className={styles.orangeBorderBottom} />
    </div>
  );
};

export default FeatureBox;
