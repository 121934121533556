export const topSectionCopy = {
    mainHeading: "Create Cloning Plans In Minutes",
    introText: "LabKick is an intuitive, end-to-end copilot built to supercharge your cloning capabilities.",
    dividerText: {
        part1: "Can you kick it?",
        part2: "Yes, you can. "
    },
    features: [
        {
            header: "Work like<br />a scientist.",
            text: "Use natural language to plan. No complicated interfaces or endless drop-downs."
        },
        {
            header: "Boost your productivity.",
            text: "Generate lab notebook entries, order forms, and schedules at the click of a button."
        },
        {
            header: "Use the methods you know.",
            text: "Specify common assembly methods (Gibson, Golden Gate, etc.) and kits (NEB, Thermo)"
        },
        {
            header: "Tap into powerful tools.",
            text: "Annotate sequences, generate Primers with Primer3, and auto-calculate volumes."
        },
        {
            header: "Troubleshoot<br />with ease.",
            text: "Review interpretable and actionable errors and warnings before generating your plan."
        },
        {
            header: "Keep your<br />files tidy.",
            text: "Store all your plans and sequences in one place, never more than one @ away."
        }
    ]
};

export const benefitItemCopy = {
    heading: "Got questions? We're all about answers.",
    faqs: [
        {
            question: "What assembly methods do you support?",
            answer: "We support Gibson, Golden Gate, and Traditional cloning methods, including all relevant PCRs, digests, and order forms you need for the parts. In the future, we intend to expand to support more methods like Gateway, TA, InFusion, NEB HiFi, CRISPR and TOPO."
        },
        {
            question: "What sort of fancy checks do you use?",
            answer: "We make custom primers, perform graph checks of sequence homology, and more. Our system includes interpretable and actionable errors and warnings before generating your plan."
        },
        {
            question: "Are there examples I can look at?",
            answer: "Please check out <a href='https://www.labkick.ai/appnotes' target='_blank' rel='noopener noreferrer'>www.labkick.ai/appnotes</a> for app notes of common use cases."
        },
        {
            question: "What languages do you support?",
            answer: "We support 10 common languages and are happy to support yours if needed. Just reach out to let us know what language you'd like to use."
        },
        {
            question: "How do I subscribe?",
            answer: "We charge per design, because we only believe you should pay for what you use. This way you're never locked into a subscription you don't need. We support all major credit cards. If you are an academic professor that would like to pay for your group or a company, please email <a href='mailto:sales@labkick.ai' target='_blank' rel='noopener noreferrer'>sales@labkick.ai</a>."
        },
        {
            question: "How do I request new features, report bugs, or get support?",
            answer: "Please reach out to <a href='mailto:support@labkick.ai' target='_blank' rel='noopener noreferrer'>support@labkick.ai</a>. For bug reports, screenshots and descriptions are always appreciated! We're always excited to hear your feature suggestions and work hard to expand our capabilities."
        }
    ]
};

export const frameComponent2Copy = {
    heading: "Let's get kicking.",
    copyright: "© 2024 LabKick. All rights reserved.",
    socialLinks: {
        twitter: "https://x.com/LabKickAI",
        linkedin: "https://www.linkedin.com/company/labkick/"
    },
    footerLinks: {
        contact: {
            text: "Contact info",
            href: "mailto:info@labkick.ai"
        },
        privacy: {
            text: "Privacy Policy",
            href: "/privacy_policy_labkick.html"
        },
        terms: {
            text: "Terms of Service",
            href: "/terms_of_service_labkick.html"
        }
    }
};

export const paymentTiersCopy = {
    academic: {
        title: "Academic",
        pricing: {
            price: "$9",
            subtitle: "per design"
        },
        button: {
            text: "purchase now",
            url: "https://app.labkick.ai"
        },
        features: [
            "Unlimited chats",
            "Enterprise-grade security",
            "Standard A.I. model access"
        ]
    },
    professional: {
        title: "Professional",
        pricing: {
            price: "$99",
            subtitle: "per design"
        },
        button: {
            text: "purchase now",
            url: "https://app.labkick.ai"
        },
        features: [
            "Unlimited chats",
            "Enterprise-grade security",
            "Advanced A.I. model access"
        ]
    },
    enterprise: {
        title: "Enterprise",
        description: [
            "Custom solutions available",
            "for large teams and labs"
        ],
        button: {
            text: "contact us",
            url: "mailto:sales@labkick.ai"
        },
        features: [
            "Unlimited chats",
            "Enterprise-grade security",
            "Advanced A.I. model access",
            "ELN & project management integrations",
            "Robotic automation integrations",
            "And more"
        ]
    }
};

export const secondSectionCopy = {
    splashStatement: "LabKick's easy-to-use text interface is designed to simplify the steps between where you are and where you're headed. If you can design it, we can help you build it. Check out an example below.",
    actionBoxes: [
        {
            direction: "right",
            header: "Upload your sequences.",
            body: "Use any commmon file formats or just paste in a sequence. We annotate sequences for you upon upload by default.",
            image: "/example@2x.png"
        },
        {
            direction: "left",
            header: "Describe your plan.",
            body: "Tell LabKick what you want to build and how you want to build it. We support plans as simple as subcloning a single gene and as complex as creating a multi-part combinatorial library.",
            image: "/example@2x.png"
        },
        {
            direction: "right",
            header: "Validate your design.",
            body: "LabKick will check that your plan will work and will generate interpretable error messages and warnings. If it finds an error or you change your mind on your plan, a revised design is just a prompt away.",
            image: "/example@2x.png"
        },
        {
            direction: "left",
            header: "Generate your outputs.",
            body: "Have LabKick design your primers, generate your lab notebook entries, create order forms for your favorite vendor, and calculate the parameters for your favorite protocols. And don't worry: protocols are easy to copy paste into your favorite lab notebook.",
            image: "/example@2x.png"
        }
    ]
};

export const paymentSectionCopy = {
    heading: "Built for your lab.",
    subheading: "Whether you kick it solo or with a big research team, LabKick's ready to assist you."
};
